/* eslint-disable react-hooks/exhaustive-deps */
import { ProgramConfig } from 'api/interfaces/program/program.interface';
import InfoIcon from 'assets/icons/InfoIcon';
import { themeColors } from 'assets/theme/style';
import LoaderFullPage from 'components/common/loader/LoaderFullPage';
import CurrencyTile from 'components/CurrencyTile';
import BarChartDashboard from 'components/dashboards/BarChart';
import DoughnutChart from 'components/dashboards/DoughnutChart';
import FirstTimeUsersBanner from 'components/FirstTimeUsersBanner/FirstTimeUsersBanner';
import GatewayToFinanceBar from 'components/home/GatewayToFinanceBar';
import Notification from 'components/Notification';
import WelcomeGuidancePanel from 'components/WelcomeGuidancePanel';
import LayoutViewContainer from 'layout/hoc/LayoutViewContainer';
import { ProgramType } from 'lib/enums/program/programType.enum';
import { getCookie } from 'lib/helpers/cookieHelper';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SET_REDIRECT_PATH, UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY } from 'store/actions';
import { IGroupedProgram } from 'utils/interfaces/program/program.interface';
import { HomeChartView, HomeCurrencyWrapper, HomeOverviewContainer } from './styled';
import GetPaidNowAvailableFundsPanel from 'components/dashboards/panels/GetPaidNowAvailablePanel';
import { useAuth } from 'react-oidc-context';
import NotificationBanner from "../../components/AuthUpdateBanner";
import {DateTime} from "luxon";

const Home: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [g2FBannerOpen, setG2FBannerOpen] = useState<boolean>(true);
  const showWelcomeGuidance: boolean = getCookie('spOnboardWelcomeGuidance') === 'completed';
  const remindFirstTimeUsers: boolean = getCookie('spRemindFirstTimeUsers') === 'true';

  const {
    redirectedPath,
    loading: appLoading,
    onboardingComplete,
    g2fConnection,
    g2fFeatureHidden,
    programType,
    programs,
    groupedPrograms
  }: {
    loading: boolean;
    onboardingComplete: boolean | null;
    redirectedPath: string | null;
    g2fConnection: any;
    g2fFeatureHidden: boolean;
    programType: string;
    programs: ProgramConfig[];
    groupedPrograms: IGroupedProgram[];
  } = useSelector((state: RootStateOrAny) => state.app);

  const funderName = programs
    .find(
      (program) =>
        program.baseType.includes(ProgramType.SP_FUNDING) ||
        program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
    )
    ?.participants.find((p) => p.role === 'FUNDER')?.registeredName;

  const buyerName = programs
    .find(
      (program) =>
        program.baseType.includes(ProgramType.SP_FUNDING) ||
        program.baseType.includes(ProgramType.EXTERNAL_FUNDING)
    )
    ?.participants.find((p) => p.role === 'BUYER')?.registeredName;

  // useEffect(() => {
  //   if (redirectedPath) {
  //     navigate(redirectedPath);
  //     dispatch({ type: SET_REDIRECT_PATH, payload: null });
  //   }
  // }, [redirectedPath]);

  const selectDashboard: () => JSX.Element = () => {
    switch (programType) {
      case ProgramType.OA_EXTERNAL_FUNDING:
        return renderOAExternalFundingDashboard();
      default:
        return renderDefaultDashboard();
    }
  };

  const clickHandler: (program: IGroupedProgram) => void = (program) => {
    dispatch({
      type: UPDATE_ACTIVE_PROGRAMS_BY_CURRENCY,
      payload: { program, accessToken: auth.user?.access_token }
    });
  };

  const renderOAExternalFundingDashboard: () => JSX.Element = () => (
    <HomeChartView>
      <DoughnutChart direction="row" />
    </HomeChartView>
  );

  const renderDefaultDashboard: () => JSX.Element = () => (
    <HomeOverviewContainer>
      {(programType === ProgramType.OA_EXTERNAL_FUNDING ||
        programType === ProgramType.OA_FUNDING) && <GetPaidNowAvailableFundsPanel />}
      <HomeChartView>
        <DoughnutChart />
        <BarChartDashboard />
      </HomeChartView>
    </HomeOverviewContainer>
  );

  const renderDashboard: () => JSX.Element = () => (
    <LayoutViewContainer size="xlarge" data-testid="sp-home-container">
      <HomeOverviewContainer>
        {!showWelcomeGuidance && <WelcomeGuidancePanel />}

        {!g2fFeatureHidden &&
          g2FBannerOpen &&
          (!g2fConnection || g2fConnection.status !== 'Active') && (
            <GatewayToFinanceBar closeHandler={() => setG2FBannerOpen(!g2FBannerOpen)} />
          )}
        {
            programs.find((program)=>program.id==='603ff1b1-b6f8-4d44-87ce-e70f06ea61c8') && <NotificationBanner />
        }
        {!remindFirstTimeUsers && <FirstTimeUsersBanner />}
        {programType === ProgramType.OA_EXTERNAL_FUNDING && (
          <Notification
            noShadow
            backgroundColor={themeColors.bg.infoMuted}
            color={themeColors.text.primary}
            icon={<InfoIcon />}
            description={`All your approved invoices are managed by ${funderName}.
        Please contact ${buyerName} or ${funderName} for further information.`}
          />
        )}
        <HomeCurrencyWrapper>
          {groupedPrograms.length > 1 &&
            groupedPrograms.map((program, i) => (
              <CurrencyTile
                key={i}
                currencyCode={program.currency}
                active={program.active}
                clickHandler={() => clickHandler(program)}
              />
            ))}
        </HomeCurrencyWrapper>

        {selectDashboard()}
      </HomeOverviewContainer>
    </LayoutViewContainer>
  );

  return appLoading || onboardingComplete === null ? <LoaderFullPage /> : renderDashboard();
};

export default Home;
